import $ from "jquery";


$(function() {
    $(".header__menu-hamburger, .header__menu-close").click(function(e){
        e.preventDefault();
        
        $('.header__mobile-menu-wrapper').toggleClass("menu-visible");
        $('.header__menu-close').toggleClass("header__menu-close--closed");
        $('body').toggleClass("body-menu-visible");
    });
});
